import React, {useEffect} from "react";
import styles from "./LoadingBar.module.scss"
const LoadingBar = props => {

    useEffect(()=> {

        if(props.show) {
            setTimeout(()=> {
                props.setShowLoadingBar(false)
            },1500)
        }

    }, [props.show])


    const div = props.show ? <div className={styles.loader}/> : false

    return div

}

export default LoadingBar
