import React, {useRef, useEffect, useState} from "react";
import {useStore} from "../../store/store";

const CheckOnScreen = props => {

    const [state] = useStore();
    const [onScreen, setOnScreen] = useState(false);
    const div = useRef();

    const scroll = state.windowScroll || 0;

    useEffect(()=>{

        const withWindowHeight = props.top ? 0 : window.innerHeight;
        const offset = props.offset ? props.offset : 0

        if(scroll + withWindowHeight + offset >=  div.current.offsetTop) {

            //If already on screen don't run callback
            if(onScreen) return
            if(props.OnPage){
                props.OnPage()
            }

            setOnScreen(true)
        } else {

            //If already off screen don't run callback
            if(!onScreen) return
            if(props.OffPage){
                props.OffPage()
            }


            setOnScreen(false)
        }

    },[state.windowScroll])

    return (
        <div ref={div}>
            {props.children}
        </div>
    )

}

export default CheckOnScreen;
