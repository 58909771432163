import React from "react";
import styles from "./WorkTell.module.scss";
import Link from "../../../../components/Link/Link";

const WorkTell = props => {

    const tellClasses = [styles.tell]

    if(props.show){
        tellClasses.push(styles.tellShow)
    }

    if(props.moving){
        tellClasses.push(styles.tellMove)
    }

    const subject = "Tell me about " + props.title

    const email = () => {
        window.open("mailto:tell@minimmmal.com?subject=" + subject)
    }

    return (
        <div className={tellClasses.join(" ")}>
            <div className={styles.header}>
                <span>Tell: {props.title}</span>
                <div className={styles.link}>
                    <Link whiteClose={true} callback={props.closeTell}>Don't tell</Link>
                </div>
            </div>

            <div className={styles.tellLink}>
                <Link size="linkTell" callback={email}>
                    tell@minimmmal.com
                </Link>
            </div>


        </div>
    )

}

export default WorkTell;
