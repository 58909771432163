import React from "react";
import Logo from "../../components/Logo/Logo";
import styles from "./Header.module.scss";
import Link from "../../components/Link/Link";
import {useStore} from "../../store/store";

const Header = props => {

    const [state] = useStore();

    const headerClasses = [styles.header];
    const logoClasses = [styles.logo]

    //console.log(state)

    if(state.windowScrollDown){
        headerClasses.push(styles.hide);
        logoClasses.push(styles.hide);
    }

    if(props.menuOpen && !props.moving) {
        headerClasses.push(styles.dark)
    }

    if(props.moving) {
        headerClasses.push(styles.moving)
    }


    return (
        <React.Fragment>

            <div className={headerClasses.join(" ")}>
                <div className={[styles.links, styles.show].join(" ")}>
                    <Link callback={()=>props.openMenu('show')}>Show</Link>
                </div>
                <div className={[styles.links, styles.tell].join(" ")}>
                    <Link callback={()=>props.openMenu('tell')}>Tell</Link>
                </div>
            </div>

            <div className={logoClasses.join(" ")}>
                <Logo callback={props.closeMenu}/>
            </div>

        </React.Fragment>


    )
}

export default Header;
