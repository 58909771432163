import React, {lazy} from "react";

export const showList = [
    {
        year: 2019,
        title: "Anais and co",
        type: ['design', 'code', 'video'],
    },
    {
        year: 2021,
        title: "Harrods",
        type: ['design'],
    },
    {
        year: 2021,
        title: "Bentley",
        type: ['design', 'video'],
        url: "bentley",
        file: lazy(()=>import("../layout/Page/Work/Pages/Bentley/Bentley"))
    },
    {
        year: 2021,
        title: "Dribbble",
        type: ['design', 'video'],
        url: "dribbble",
        file: lazy(()=>import("../layout/Page/Work/Pages/Dribbble/Dribbble"))
    },
    {
        year: 2021,
        title: "Michelin",
        type: ['code'],
    },
    {
        year: 2021,
        title: "Microsoft",
        type: ['code'],
    },
    {
        year: 2020,
        title: "FIA",
        type: ['code'],
    },
    {
        year: 2020,
        title: "Feedly",
        type: ['design', 'video'],
    },
    {
        year: 2020,
        title: "Google",
        type: ['design'],
    },
    {
        year: 2021,
        title: "Linkedin",
        type: ['design', 'video'],
    },
    {
        year: 2020,
        title: "Amazon",
        type: ['design', 'video'],
    }
]
