import React, {useState} from "react";
import styles from "./Link.module.scss";

import blackClose from  "./black-close.svg";
import whiteClose from "./white-close.svg";
import blackPlus from "./black-add.svg";

const Link = props => {

    const [hover, setHover] = useState(false)
    const [click, setClick] = useState(false)

    const linkClasses = [styles.link]

    const linkClicked = () => {
        setClick(true);
        setTimeout(()=>{
            setClick(false)
        },2000)

        if(props.callback){
            props.callback()
        }

    }


    if(props.color) {
        linkClasses.push(styles[props.color])
    }

    if(props.size) {
        linkClasses.push(styles[props.size])
    }

    if(props.color) {
        linkClasses.push(styles[props.color])
    }

    if(hover && !click){
        linkClasses.push(styles.hover)
    }

    if(click) {
        linkClasses.push(styles.click)
    }

    if(props.blackClose || props.whiteClose || props.blackPlus){
        linkClasses.push(styles.flex)
    }

    return (
        <span
            className={linkClasses.join(" ")}
            onMouseOver={()=>setHover(true)}
            onMouseLeave={()=>setHover(false)}
            onClick={linkClicked}
        >
            {props.blackClose && <img src={blackClose} alt=" "/>}
            {props.whiteClose && <img src={whiteClose} alt=" "/>}
            {props.blackPlus && <img src={blackPlus} alt=" "/>}
            {props.children}
        </span>
    )

}

export default Link;
