import React from "react";
import styles from "./MenuList.module.scss";

const MenuList = props => {

    const listClasses = [styles.menuList];

    if(props.hideNav){
        listClasses.push(styles.hide)
    }

    return (
        <div className={listClasses.join(" ")}>{props.children}</div>
    )

}

export default MenuList;
