import React from "react";
import styles from "./Menu.module.scss";
import Show from "./Show/Show";
import Tell from "./Tell/Tell";

const Menu = props => {


    const menuClasses = [styles.menu]
    const bgClasses = [styles.background]

    if(props.showOpen || props.tellOpen) {
        menuClasses.push(styles.open)
        bgClasses.push(styles.bgOpen)
    }

    if(props.moving){
        menuClasses.push(styles.moving)
        bgClasses.push(styles.moving)
    }

    if(props.fade){
        menuClasses.push(styles.fade)
        bgClasses.push(styles.fade)
    }

    return (
        <React.Fragment>
            <div className={menuClasses.join(" ")}>

                <Show
                    active={props.showOpen && !props.moving}
                    moving={props.moving}
                    changing={props.changing}
                    openWork={props.openWork}
                    setShowLoadingBar={props.setShowLoadingBar}
                />
                <Tell
                    active={props.tellOpen && !props.moving}
                    moving={props.moving}
                    changing={props.changing}
                    setShowLoadingBar={props.setShowLoadingBar}
                />

            </div>
            <div className={bgClasses.join(" ")}/>
        </React.Fragment>


    )
}

export default Menu;
