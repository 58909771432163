import React from "react";
import styles from "./Loader.module.scss"
import Logo from "../../../components/Logo/Logo";

const Loader = () => {


    return (
        <div className={styles.loader}>
            <div className={styles.text}>
                <span><Logo/></span>
            </div>
        </div>
    )

}

export default Loader
