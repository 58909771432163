import React, {Suspense} from "react";
import styles from "./WorkBody.module.scss";
import {Route, Routes} from "react-router-dom";
import {showList} from "../../../../Utils/showList";


const WorkBody = props => {

    const bodyClasses = [styles.body]

    if(props.fadeOut){
        bodyClasses.push(styles.bodyHide)
    }

    return (

        <div className={bodyClasses.join(" ")}>
            <Suspense fallback={<div>...</div>}>
                <Routes>
                    <Route path="/" element={<div>404</div>} />
                    {/*<Route path="/work/dribbble" element={<Dribbble setTitle={props.setTitle} />} />*/}
                    {showList.map((work, i)=>{
                        if(work.file){
                            const path = "/work/" + work.url
                            const Component = work.file
                            return <Route key={i} path={path} element={<Component setTitle={props.setTitle}/>} />
                        } else {
                            return false
                        }
                    })}


                    <Route path="/work/*" element={<div>404</div>} />
                </Routes>
            </Suspense>
        </div>

    )
}

export default WorkBody
