import React from "react";
import styles from "./WorkFooter.module.scss";
import Link from "../../../../components/Link/Link";

const WorkFooter = props => {


    const footerClasses = [styles.footer]

    if(props.show){
        footerClasses.push(styles.footerShow)
    }


    return (
        <div className={footerClasses.join(" ")}>
            <div className={styles.link}>
                <Link color="black" blackPlus={true} callback={props.openTell}>Tell</Link>
            </div>
        </div>
    )
}

export default WorkFooter
