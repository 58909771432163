import React, {useEffect} from 'react';
import './App.scss';
import Loader from "./layout/Page/Loader/Loader";

import MainLayout from "./layout/MainLayout";

//Register state management
import store from "./store";
import {useStore} from "./store/store";
store();



//const MainLayout = lazy(() => import("./layout/MainLayout"))

const App = () => {

    const [, dispatch] = useStore(false);


    useEffect(()=>{
        window.addEventListener("scroll", e=>{
            const scroll = window.pageYOffset || document.documentElement.scrollTop
            const scrollAdj = scroll < 0 ? 0 : scroll;
            dispatch('WINDOW_SCROLL', scrollAdj);
        });
    },[])

    return (
        <React.Fragment>
            <Loader/>
            <MainLayout dispatch={dispatch}/>
        </React.Fragment>
    )

}

export default App;
