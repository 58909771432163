import React from "react";
import styles from "./Home.module.scss";
import Link from "../../../components/Link/Link";

const Home = props => {

    return (
        <div className={styles.home}>
            <h1>
                <Link size="linkLg" color="black" callback={()=>props.openMenu('show')}>Show</Link>
                don’t
                <Link size="linkLg" color="black" callback={()=>props.openMenu('tell')}>tell.</Link></h1>
        </div>
    )

}

export default Home;
