import React from "react";
import styles from "./WorkHeader.module.scss";
import Link from "../../../../components/Link/Link";


const WorkHeader = props => {

    const headerClasses = [styles.header]

    if(props.hide){
        headerClasses.push(styles.HeaderHide)
    }

    return (
        <div className={headerClasses.join(" ")}>
            <span>Show: {props.title}</span>
            <div className={styles.link}>
                <Link color="black" blackClose={true} callback={props.closeWork}>Don't show</Link>
            </div>

        </div>
    )

}

export default WorkHeader;
