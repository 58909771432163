import React, {useState} from "react";
import styles from "./Work.module.scss"
import {useStore} from "../../../store/store";
import WorkHeader from "./WorkHeader/WorkHeader";
import WorkFooter from "./WorkFooter/WorkFooter";
import WorkBody from "./WorkBody/WorkBody";
import WorkTell from "./WorkTell/WorkTell";

const Work = props => {

    const [state] = useStore();

    const [title, setTitle] = useState("")
    const [fadeOut, setFadeOut] = useState(false)
    const [showTell, setShowTell] = useState(false)
    const [showTellMoving, setShowTellMoving] = useState(false)

    const workClasses = [styles.work]
    const bgClasses = [styles.background]


    if(props.workOpen){
        workClasses.push(styles.active)
        bgClasses.push(styles.backgroundActive)
    }

    if(props.workOpening){
        workClasses.push(styles.opening)
    }

    if(props.closingWork){
        workClasses.push(styles.closing)
        bgClasses.push(styles.backgroundClosing)
    }


    const closeWork = () => {
        setFadeOut(true)

        setTimeout(()=>{
            props.closeWork()
        },500)

        setTimeout(()=> {
            setFadeOut(false)
        },1200)
    }

    const openTell = () => {
        props.setShowLoadingBar(true)
        setShowTell(true)
        document.body.style.overflow = 'hidden';
    }

    const closeTell = () => {
        document.body.style.overflow = 'auto';
        setShowTellMoving(true)
        setShowTell(false)
        setTimeout(()=>{
            setShowTellMoving(false)
        }, 1000)

    }

    return (

        <React.Fragment>

            <div className={workClasses.join(" ")}>

                <WorkHeader
                    hide={state.windowScrollDown && !props.workOpening && props.workOpen && !props.closingWork}
                    closeWork={closeWork}
                    title={title}
                />

                <WorkBody fadeOut={fadeOut} setTitle={setTitle}/>

                <WorkFooter
                    show={state.windowScrollDown && state.windowScroll > 100}
                    openTell={openTell}
                />

            </div>

            <div className={bgClasses.join(" ")}/>

            <WorkTell
                title={title}
                show={showTell}
                moving={showTellMoving}
                closeTell={closeTell}
            />

        </React.Fragment>

    )

}

export default Work
