import React from "react";
import styles from "./MenuLayout.module.scss"
import {useStore} from "../../../store/store";

const MenuLayout = props => {

    const [state] = useStore();

    const outerClass = [styles.menuLayout];
    const navClasses = [styles.nav]

    if(props.active){
        outerClass.push(styles.active)
    }

    if(state.windowScrollDown){
        outerClass.push(styles.scrollDown)
        navClasses.push(styles.scrollDownNav)
    }

    if(props.moving) {
        outerClass.push(styles.firstEnter)
    }

    if(props.changing){
        outerClass.push(styles.changing)
    }


    return (
        <div className={outerClass.join(" ")}>
            <div className={navClasses.join(" ")} >
                {props.nav}
            </div>
            <div className={styles.list}>
                {props.list}
            </div>

        </div>


    )

}

export default MenuLayout;
