import React, {useState} from "react";
import MenuLayout from "../MenuLayout/MenuLayout";
import MenuList from "../MenuLayout/MenuList/MenuList";
import Link from "../../../components/Link/Link";
import {useNavigate} from "react-router-dom";
import {showList} from "../../../Utils/showList";

const Show = props => {

    const navigate = useNavigate();

    const workList = showList;

    const [hideNav, setHideNav] = useState(false)
    const [filterBy, setFilterBy] = useState('everything')

    const [activeMenu, setActiveMenu] = useState({
        everything: true,
        design: false,
        code: false,
        video: false
    });

    const SetActive = menu => {
        //No need to reset
        if(activeMenu[menu]) return

        changeList(menu)

        if(menu === "design"){
            setActiveMenu({
                everything: false,
                design: true,
                code: false,
                video: false
            })
        } else if (menu === "code") {
            setActiveMenu({
                everything: false,
                design: false,
                code: true,
                video: false
            })
        } else if (menu === "video") {
            setActiveMenu({
                everything: false,
                design: false,
                code: false,
                video: true
            })
        } else {
            setActiveMenu({
                everything: true,
                design: false,
                code: false,
                video: false
            })
        }

    }

    const changeList = menu => {

        setHideNav(true);
        props.setShowLoadingBar(true);

        setTimeout(()=> {
            setFilterBy(menu);
            setHideNav(false);
        }, 750)


    }

    const openSite = url => {
        if(!url) return

        //Open work popup
        props.openWork()

        navigate('work/'+ url)
    }


    const ShowNav = <ul>
        <li onClick={()=>SetActive("everything")}>
            <Link size="linkMenu" color={!activeMenu.everything ? 'gray' : false }>
                Everything
            </Link>

        </li>
        <li onClick={()=>SetActive("design")}>
            <Link size="linkMenu" color={!activeMenu.design ? 'gray' : false }>
                Design
            </Link>
        </li>
        <li onClick={()=>SetActive("code")}>
            <Link size="linkMenu" color={!activeMenu.code ? 'gray' : false }>
                Code
            </Link>
        </li>
        <li onClick={()=>SetActive("video")}>
            <Link size="linkMenu" color={!activeMenu.video ? 'gray' : false }>
                Video
            </Link>
        </li>
    </ul>

    const ShowList = <MenuList hideNav={hideNav}>
        <ul>

            {workList.map((work, i) => {
                let line = false
                if (work.type.includes(filterBy) || filterBy === "everything") {

                    const detail =
                        <React.Fragment>
                            <div>{work.year}</div>
                            <div>{work.title}</div>
                            <div>
                                {work.type.map((type, i) => {
                                    const end = i < work.type.length - 1 ? " + " : "";
                                    return type + end
                                })}
                            </div>
                        </React.Fragment>

                    //If no URL remove link
                    if(work.file){
                        line =
                            <li key={i}>
                                <Link callback={()=>openSite(work.url)} size="linkList">
                                    {detail}
                                </Link>
                            </li>

                    } else {
                        line =
                            <li key={i}>
                                {detail}
                            </li>
                    }
                }
                return line
            })}



        </ul>


    </MenuList>


    return (
        <MenuLayout
            active={props.active}
            moving={props.moving}
            changing={props.changing}
            nav={ShowNav}
            list={ShowList}

        />
    )
}

export default Show;
