import React from "react";
import styles from "./ShowFooter.module.scss"

const ShowFooter = props => {


    return (
        <div className={styles.showFooter} onClick={props.callback}>
            Show
        </div>
    )

}

export default ShowFooter;
