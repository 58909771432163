import React, {useEffect, useRef, useState} from "react";
import MenuLayout from "../MenuLayout/MenuLayout";
import MenuList from "../MenuLayout/MenuList/MenuList";
import CheckOnScreen from "../../../components/CheckOnScreen/CheckOnScreen";
import Link from "../../../components/Link/Link";

const Tell = props => {

    const [hideNav, setHideNav] = useState(false)
    const [filterBy, setFilterBy] = useState('capabilities')

    const [activeMenu, setActiveMenu] = useState({
        capabilities: true,
        contact: false,
        social: false
    });

    const SetActive = menu => {
        //No need to reset
        if(activeMenu[menu]) return

        changeList(menu)

        if(menu === "social"){
            setActiveMenu({
                capabilities: false,
                contact: false,
                social: true
            })
        } else if (menu === "contact") {
            setActiveMenu({
                capabilities: false,
                contact: true,
                social: false
            })
        } else {
            setActiveMenu({
                capabilities: true,
                contact: false,
                social: false
            })
        }

    }

    const changeList = menu => {

        setHideNav(true);
        props.setShowLoadingBar(true);

        setTimeout(()=> {
            setFilterBy(menu);
            setHideNav(false);
        }, 750)


    }

    // const capabilities = useRef()
    // const contact = useRef()
    // const social = useRef()

    // useEffect(()=> {
    //     CheckActive('capabilities')
    // }, [props.changing])

    // const scrollTo = item => {
    //     //Account for header offset
    //     const yOffset = -156;
    //     const y = item.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
    //     window.scrollTo({top: y, behavior: 'smooth'});
    // }

    // const CheckActive = menu => {
    //
    //     //No need to reset
    //     if(activeMenu[menu]) return
    //
    //     if(menu === "social"){
    //         setActiveMenu({
    //             capabilities: false,
    //             contact: false,
    //             social: true
    //         })
    //     } else if (menu === "contact") {
    //         setActiveMenu({
    //             capabilities: false,
    //             contact: true,
    //             social: false
    //         })
    //     } else {
    //         setActiveMenu({
    //             capabilities: true,
    //             contact: false,
    //             social: false
    //         })
    //     }
    //
    // }



    const TellNav = <ul>
        <li onClick={()=>SetActive("capabilities")}>
            <Link size="linkMenu" color={!activeMenu.capabilities ? 'gray' : false }>
                Capabilities
            </Link>
        </li>
        <li onClick={()=>SetActive("contact")}>
            <Link size="linkMenu" color={!activeMenu.contact ? 'gray' : false }>
                Contact
            </Link>
        </li>
        <li onClick={()=>SetActive("social")}>
            <Link size="linkMenu" color={!activeMenu.social ? 'gray' : false }>
                Social
            </Link>
        </li>
    </ul>


    const TellList = <MenuList hideNav={hideNav}>
        {filterBy === "capabilities" && <ul>
            <li>
                    <span>
                        <div>001</div>
                        <div>UX design</div>
                    </span>
            </li>
            <li>
                    <span>
                        <div>002</div>
                        <div>UI design</div>
                    </span>

            </li>
            <li>
                    <span>
                        <div>003</div>
                        <div>Motion design</div>
                    </span>

            </li>
            <li>
                    <span>
                        <div>004</div>
                        <div>Video</div>
                    </span>

            </li>
            <li>
                    <span>
                        <div>005</div>
                        <div>Photography</div>
                    </span>

            </li>
            <li>
                    <span>
                        <div>006</div>
                        <div>Branding</div>
                    </span>

            </li>
            <li>
                    <span>
                        <div>007</div>
                        <div>Product design strategy</div>
                    </span>

            </li>
            <li>
                    <span>
                        <div>008</div>
                        <div>SEO</div>
                    </span>

            </li>
            <li>
                    <span>
                        <div>009</div>
                        <div>PPC</div>
                    </span>

            </li>
            <li>
                    <span>
                        <div>010</div>
                        <div>Front-end development</div>
                    </span>

            </li>
            <li>
                    <span>
                        <div>011</div>
                        <div>Design system</div>
                    </span>

            </li>
        </ul>}
        {filterBy === "contact" && <ul>
            <li>
                <Link callback={()=>window.open("mailto:london@minimmmal.com")} size="linkList">
                    <div>001</div>
                    <div>london@minimmmal.com</div>
                </Link>
            </li>
            <li>
                <Link callback={()=>window.open("mailto:paris@minimmmal.com")} size="linkList">
                    <div>002</div>
                    <div>paris@minimmmal.com</div>
                </Link>
            </li>
            <li>
                <Link callback={()=>window.open("mailto:madrid@minimmmal.com")} size="linkList">
                    <div>003</div>
                    <div>madrid@minimmmal.com</div>
                </Link>

            </li>
            <li>
                <Link callback={()=>window.open("mailto:careers@minimmmal.com")} size="linkList">
                    <div>004</div>
                    <div>careers@minimmmal.com</div>
                </Link>

            </li>
            <li>
                <Link callback={()=>window.open("mailto:press@minimmmal.com")} size="linkList">
                   <div>005</div>
                    <div>press@minimmmal.com</div>
                </Link>

            </li>

        </ul>}
        {filterBy === "social" && <ul>
            <li>
                <Link callback={()=>window.open("https://dribbble.com/gregoirevella")} size="linkList">
                    <div>006</div>
                    <div>Dribbble</div>
                </Link>

            </li>
            <li>
                <Link callback={()=>window.open("https://www.behance.net/gregoirevella")} size="linkList">
                    <div>007</div>
                    <div>Behance</div>
                </Link>

            </li>
            <li>
                <Link callback={()=>window.open("https://www.behance.net/gregoirevella")} size="linkList">
                    <div>008</div>
                    <div>Vimeo</div>
                </Link>

            </li>
            <li>
                <Link callback={()=>window.open("https://www.instagram.com/")} size="linkList">
                    <div>009</div>
                    <div>Instagram</div>
                </Link>

            </li>
            <li>
                <Link callback={()=>window.open("https://www.linkedin.com/")} size="linkList">
                    <div>010</div>
                    <div>Linkedin</div>
                </Link>

            </li>

        </ul>}
    </MenuList>


    return (
        <MenuLayout
            active={props.active}
            moving={props.moving}
            changing={props.changing}
            nav={TellNav}
            list={TellList}/>
    )

}

export default Tell;
